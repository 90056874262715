import React, { PureComponent } from "react";
import { Container, Form, Button, ProgressBar, Card, Spinner  } from "react-bootstrap";
import MaskedInput from "react-maskedinput";
import background from "../../adwumapa-background.jpeg"; // Your background image
import logo from "../../starlife-logo.png";
import Swal from "sweetalert2";
import axios from "axios";

class AdwumapaRegistration extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      step: 1,
      subscriberName: "",
      subscriberNumber: "",
      ghanaCard: "",
      beneficiaryName: "",
      beneficiaryNumber: "",
      errors: {},
    };
  }

  nextStep = () => {
    if (this.validateFields()) {
      this.setState((prevState) => ({ step: prevState.step + 1 }));
    }
  };

  prevStep = () => {
    this.setState((prevState) => ({ step: prevState.step - 1 }));
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateFields = () => {
    const { step, subscriberName, subscriberNumber, ghanaCard, beneficiaryName, beneficiaryNumber } = this.state;
    let errors = {};
    let isValid = true;
    const phoneRegex = /^[0-9]{10}$/;

    if (step === 1) {
      if (!subscriberName.trim()) {
        errors.subscriberName = "Name is required";
        isValid = false;
      }
      if (!subscriberNumber.trim()) {
        errors.subscriberNumber = "Phone number is required";
        isValid = false;
      }else if (!phoneRegex.test(subscriberNumber)) {
        errors.subscriberNumber = "Enter a valid 10-digit phone number";
        isValid = false;
      }
      if (!ghanaCard.trim()) {
        errors.ghanaCard = "Ghana card number is required";
        isValid = false;
      }
    } else if (step === 2) {
      if (!beneficiaryName.trim()) {
        errors.beneficiaryName = "Beneficiary Name is required";
        isValid = false;
      }
      if (!beneficiaryNumber.trim()) {
        errors.beneficiaryNumber = "Beneficiary Phone number is required";
        isValid = false;
      }else if (!phoneRegex.test(beneficiaryNumber)) {
        errors.beneficiaryNumber = "Enter a valid 10-digit phone number";
        isValid = false;
      }
    }

    this.setState({ errors });
    return isValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateFields()) {
      this.setState({ loading: true });

      const subscriber = {
        subscriberName: this.state.subscriberName,
        subscriberNumber: this.state.subscriberNumber,
        ghanaCard: "GHA-" + this.state.ghanaCard,
        beneficiaryName: this.state.beneficiaryName,
        beneficiaryNumber: this.state.beneficiaryNumber
      }

      console.log("subcriber details", subscriber);


      axios
        .post("/api/customer/adwumapa/registration", subscriber)
        .then(async (response) => {
          if (response.data.message_code === 100) {
            this.setState({loading: false})
            Swal.fire({
              title: "Success!",
              text: `Thank you ${this.state.subscriberName}, your details have been submitted successfully.`,
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              window.location.reload(); // Refresh page after success
            });
          } else {
            this.setState({loading: false})
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "An error occured submitting form. Please try again later",
            });
            // alert("An error occured submitting transactional request")
          }
        })
        .catch((error) => {
            this.setState({loading: false})
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "An error occured submitting form. Please try again later",
            });
            
        });


      // Simulate an API call (replace this with real API request)
      // setTimeout(() => {
      //   this.setState({ loading: false });

      //   Swal.fire({
      //     title: "Success!",
      //     text: `Thank you ${this.state.subscriberName}, your details have been submitted successfully.`,
      //     icon: "success",
      //     confirmButtonText: "OK",
      //   }).then(() => {
      //     window.location.reload(); // Refresh page after success
      //   });
      // }, 2000); // Simulates a 2-second loading time

      // Swal.fire({
      //   title: "Success!",
      //   text: `Yello ${this.state.subscriberName}, your details have been submitted successfully.`,
      //   icon: "success",
      //   // confirmButtonText: "OK",
      //   timer: 3000, // Closes after 3 seconds
      //   showConfirmButton: false,
      // }).then(() => {
      //   window.location.reload(); // Refreshes the page after clicking OK
      // });
      
      
      // alert(
      //   `Submitted:\nFirst Name: ${this.state.subscriberName}\nPhone: ${this.state.subscriberNumber}\nGhana Card: GHA-${this.state.ghanaCard}`
      // );
    }
  };

  render() {
    const { step, subscriberName, subscriberNumber, ghanaCard, beneficiaryName, beneficiaryNumber, errors } = this.state;
    const progress = step === 1 ? 50 : 100;

    return (
      <div style={{ ...styles.background, backgroundImage: `url(${background})` }}>
        <Container className="d-flex justify-content-center align-items-center vh-100">
          <Card style={styles.card}>
            {/* Banner Logo */}
            <img
                      src={logo}
                      alt="Logo"
                      style={styles.logo}
                    />
            <Card.Body>
              <ProgressBar now={progress} label={`${progress}%`} style={{ marginBottom: "20px" }} />
              <h4 className="text-center mb-4">Step {step} of 2</h4>

              <Form onSubmit={this.handleSubmit}>
                {step === 1 && (
                  <>
                    <div><h3><b>Subscriber Information</b></h3></div><br></br>
                    <Form.Group>
                      <Form.Label>Subscriber Full Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="subscriberName"
                        value={subscriberName}
                        onChange={this.handleChange}
                        isInvalid={!!errors.subscriberName}
                        placeholder="Enter your full name"
                      />
                      <Form.Control.Feedback type="invalid">{errors.subscriberName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Label>Subscriber Phone Number:</Form.Label>
                      <Form.Control
                        type="tel"
                        name="subscriberNumber"
                        value={subscriberNumber}
                        onChange={this.handleChange}
                        isInvalid={!!errors.subscriberNumber}
                        placeholder="Enter your phone number"
                      />
                      <Form.Control.Feedback type="invalid">{errors.subscriberNumber}</Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group className="mt-3">
                      <Form.Label>Ghana Card Number:</Form.Label>
                      <Form.Control
                        type="text"
                        name="ghanaCard"
                        value={ghanaCard}
                        onChange={this.handleChange}
                        isInvalid={!!errors.ghanaCard}
                        placeholder="Enter your ghana card number"
                      />
                      <Form.Control.Feedback type="invalid">{errors.ghanaCard}</Form.Control.Feedback>
                    </Form.Group> */}

                    <Form.Group className="mt-3">
                      <Form.Label>Ghana Card Number:</Form.Label>
                      <div className="d-flex">
                        <span style={styles.prefix}>GHA-</span>
                        <MaskedInput
                          mask="111111111-1"
                          value={ghanaCard}
                          name="ghanaCard"
                          onChange={this.handleChange}
                          className="form-control"
                          placeholder="123456789-0"
                        />
                      </div>
                      {errors.ghanaCard && <div className="text-danger mt-1">{errors.ghanaCard}</div>}
                    </Form.Group>

                    <Button variant="primary" className="mt-4 w-100" onClick={this.nextStep}>
                      Next
                    </Button>
                  </>
                )}

                {step === 2 && (
                  <>
                    <div><h3><b>Beneficiary Information</b></h3></div><br></br>
                    <Form.Group>
                      <Form.Label>Beneficiary Full Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="beneficiaryName"
                        value={beneficiaryName}
                        onChange={this.handleChange}
                        isInvalid={!!errors.beneficiaryName}
                        placeholder="Enter your beneficiary full name"
                      />
                      <Form.Control.Feedback type="invalid">{errors.beneficiaryName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Label>Beneficiary Phone Number:</Form.Label>
                      <Form.Control
                        type="tel"
                        name="beneficiaryNumber"
                        value={beneficiaryNumber}
                        onChange={this.handleChange}
                        isInvalid={!!errors.beneficiaryNumber}
                        placeholder="Enter your beneficiary phone number"
                      />
                      <Form.Control.Feedback type="invalid">{errors.beneficiaryNumber}</Form.Control.Feedback>
                    </Form.Group>

                    <div className="d-flex justify-content-between mt-4">
                      <Button variant="secondary" onClick={this.prevStep}>
                        Back
                      </Button>
                      {/* <Button variant="success" type="submit">
                        Submit
                      </Button> */}
                      <Button type="submit" variant="success" disabled={this.state.loading}>
                        {this.state.loading ? (
                          <>
                            <Spinner animation="border" size="sm" /> Submitting...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </>
                )}
              </Form>
                  <div className="copyright text-center my-auto">
                    <span>
                      Copyright &copy; {new Date().getFullYear()}. Powered by <b>StarLife I.C.T</b>
                    </span>
                  </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

const styles = {
  background: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    width: "500px",
    padding: "7px",
    textAlign: "left",
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  },
  prefix: {
    background: "#ddd",
    padding: "8px 10px",
    borderRadius: "5px 0 0 5px",
    fontWeight: "bold",
  },
};

export default AdwumapaRegistration;
