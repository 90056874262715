import React, { useState, useEffect, useContext } from "react";
import classes from "./GCBNewHeader.module.css"; // Import your component-specific SCSS file here

import gcb from "../../../gcb-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faHome,
  faSearch,
  faSignOutAlt,
  faShare,
  faTasks,
  faHistory,
  faWifi,
  faCalculator,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../../store/auth-context";
import { RWebShare } from "react-web-share";
import share from "../../../icons8-share-50.png";

const GCBNewHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.dsoLogout();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const main = document.querySelector(".main");

    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu")) {
        closeMenu();
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Escape") {
        closeMenu();
      }
    };

    main.addEventListener("click", handleClickOutside);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      main.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <>
      {authCtx.isDSOLoggedIn ? (
        <header className={classes.header}>
          <nav className={classes.navbar}>
            <div className={classes.burger} onClick={toggleMenu}>
              <span
                className={`${classes["burger-open"]} ${
                  isMenuOpen ? classes.active : ""
                }`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16">
                  <g fill="#252a32" fillRule="evenodd">
                    <path d="M0 0h24v2H0zM0 7h24v2H0zM0 14h24v2H0z" />
                  </g>
                </svg>
              </span>
              <span
                className={`${classes["burger-close"]} ${
                  isMenuOpen ? classes.active : ""
                }`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path
                    fill="#252a32"
                    fillRule="evenodd"
                    d="M17.778.808l1.414 1.414L11.414 10l7.778 7.778-1.414 1.414L10 11.414l-7.778 7.778-1.414-1.414L8.586 10 .808 2.222 2.222.808 10 8.586 17.778.808z"
                  />
                </svg>
              </span>
            </div>
            <ul
              className={`${classes.menu} ${isMenuOpen ? classes.active : ""}`}
            >
              <li className={classes["menu-item"]}>
                <a
                  href="/bancassurance/gcb/telesales"
                  className={classes["menu-link"]}
                >
                  <FontAwesomeIcon icon={faHome} title="Home" /> Home
                </a>
              </li>
              <li className={classes["menu-item"]}>
                <a href="#" className={classes["menu-link"]}>
                  <FontAwesomeIcon icon={faHistory} title="History" /> Policy
                  History
                </a>
              </li>
              <li className={classes["menu-item"]}>
                <a
                  href="#"
                  className={classes["menu-link"]}
                >
                  <FontAwesomeIcon icon={faTasks} title="Pending" /> Pending
                  Business
                </a>
              </li>
              <li className={classes["menu-item"]}>
                <a href="#" className={classes["menu-link"]}>
                  <FontAwesomeIcon icon={faWifi} title="Offline" /> Offline
                  Business
                </a>
              </li>
              <li className={classes["menu-item"]}>
                <a href="#" className={classes["menu-link"]}>
                  <FontAwesomeIcon icon={faCalculator} title="calculate" />{" "}
                  Calculate Quotation
                </a>
              </li>
              <li className={classes["menu-item"]}>
                <a href="#" className={classes["menu-link"]}>
                  <FontAwesomeIcon icon={faMoneyCheck} title="Commission" />{" "}
                  Commission Statement
                </a>
              </li>
              <li className={classes["menu-item"]}>
                <a
                  href="#"
                  className={classes["menu-link"]}
                  onClick={logoutHandler}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} title="Logout" /> Logout
                </a>
              </li>
              <li className={classes["menu-item"]}>
                <RWebShare
                  data={{
                    text: "Share - GCB Digital Onboarding",
                    url: "http://localhost:3000/bancassurance/gcb/telesales/onboarding",
                    title: "GCB Digital Onboarding",
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <a
                    className={classes["menu-link"]}
                    href="#"
                    style={{ color: "#555555" }}
                  >
                    {/* Customer Enquiry  */}
                    {/* <FontAwesomeIcon icon={faShare} title="Customer Enquiry" /> */}
                    <img
                      src={share}
                      alt="logo"
                      style={{ width: "20px", height: "20px" }}
                      title="Share - Digital Onboarding"
                      // style={{
                      //   align: "left",
                      //   left: 0,
                      // }}
                    />{" "}
                    Share
                  </a>
                </RWebShare>
              </li>
            </ul>
            <a
              className={classes["nav-link active"]}
              href="/bancassurance/gcb/telesales"
              style={{ color: "#555555" }}
            >
              <img
                src={gcb}
                alt="logo"
                //   style={{ width: "100px", height: "100px" }}
              />
            </a>
          </nav>
        </header>
      ) : (
        <>
          &nbsp;
          <div></div>
          <img
            src={gcb}
            alt="logo"
            style={{
              align: "right",
            }}
          />
        </>
      )}
      <main className="main">
        {/* <div className="container">
          <div className="wrapper"></div>
        </div> */}
      </main>
    </>
  );
};

export default GCBNewHeader;
